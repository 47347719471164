/*
* App Global CSS
* ----------------------------------------------------------------------------
* Put style rules here that you want to apply globally. These styles are for
* the entire app and not just one component. Additionally, this file can be
* used as an entry point to import other CSS/Sass files to be included in the
* output CSS.
* For more information on global stylesheets, visit the documentation:
* https://ionicframework.com/docs/layout/global-stylesheets
*/

.user-mat-menu {
    background: red;
}

.alert-confirm {
    --max-width: 60%;
    .alert-message {
        font-size: 1rem;
    }
    .loading-content {
        font-size: 1.4rem;
    }
}

.alert-toast {
  --background: black;
  --border-color: black;
  color: white;
  text-align: center;
  font-weight: bold;
  font-size: 18px;
  line-height: 24px;
  // --width: 300px;
  // --height: 200px;
}
.alert-toast-link {
  color: red;
}
.comment-prompt {
    background-color: yellow !important;
    height: 150px;
}

.chea-mat-spinner {
    margin: 10% auto 10px auto;
    circle {
      stroke: #c94b97;
    }
}

.purchase-modal {
  --height: 85%;
  --width: 85%;
  --max-width: 700px;
}

.alert-modal {
    // color: black;
    background-color: white;
    height: 100%;
    padding: 20px; 
    position: relative;
    .close-button {
      position: absolute;
      top: 10px;
      right: 10px;
      text-align: right; 
    }
    .copy-button {
      position: absolute;
      top: 10px;
      left: 10px;
      text-align: left; 
    }
    textarea {
      padding: 30px 8px; 
      width: 100%; 
      height: 100%; 
      font-size: 15px;
      border: 0;
    }
    ion-icon {
      font-size: 24px;
    }
    .content {
        font-size: 15px;
        padding: 20px 16px;
    }
  }

  .chat-player-wrapper {
    width: 100%; 
    height: 100%;
    margin: 0 auto;    
    .statement-text {
      background-color: white;
      // font-size: 18px;
      padding: 10px;
      // margin-bottom: 4px;
      // box-shadow: 3px 3px 5px #b1b0b0;
      border-radius: 15px;
      user-select: auto;
      img {
        vertical-align: middle;
      }
      p {
        margin-block-start: 0.5em;
        margin-block-end: 0.75em;
      }
    }  
  }


  .segment-title {
    padding: 30px 20px 20px 20px;
    text-align: center;
    color: var(--chea-purple);
    h1 {
      font-size: 20px;
      font-weight: bold;
      text-transform: uppercase;
      margin: 0;
      padding: 0;
    }
  }
  
  .report-block {
    padding: 16px;
    p {
        margin-block-start: 1em !important;
        margin-block-end: 1em !important;
    }
  }

  .save-spinner {
    circle {
      stroke: white;
    }
  }

.dropzone {
    margin: 4px;
    border: 2px dashed gray;
}

.contentzone {
    margin: 8px;
}

.plus-action-sheet {
  max-width: 100%;
  margin: auto;
  --backdrop-opacity: 0.55;
  --button-background-activated: darkgray; // var(--chea-hotpink);
  // --button-background-hover: lightgray;
  // --button-color-hover: orange;
  // --background: #e1bef5;
  .action-sheet-title {
    font-size: 18px;
    font-weight: bold;
    color: var(--chea-hotpink);
  }
  .action-sheet-button {
    padding: 8px;
    // height: 24px;
    min-height: 40px;
    .action-sheet-button-inner {
      font-size: 0.8em;
      color: black;
      font-weight: bold;
      // pointer-events: all;
    }
  }
  .action-sheet-icon {
    padding-right: 1px;
  }
}