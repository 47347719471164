@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */

@import "@angular/material/prebuilt-themes/indigo-pink.css";
@import url("https://fonts.googleapis.com/icon?family=Material+Icons|Material+Icons+Outlined&display=block");
@import url("https://fonts.googleapis.com/icon?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@24,500,0,0&display=block");
@import url("https://fonts.googleapis.com/css?family=Nunito+Sans:400,700&display=swap");

@layer base {
    ul {
        @apply list-disc list-outside ml-4;
    }
    ol {
        @apply list-decimal list-outside ml-4;
    }
    // p {
    //     @apply mt-4 leading-6;
    // }
}